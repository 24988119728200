import CancelIcon from '@mui/icons-material/Cancel';
import LockIcon from '@mui/icons-material/Lock';
import Chip from '@mui/material/Chip';
import { useSelector } from 'react-redux';
import { selectTeamById } from '../teamsSlice';

/**
 * TeamChip component to display a team chip from select data
 * @param {object} props
 * @param { String } props.id - the id of the team chip
 * @param { ('small'|'medium'|'large') } props.size - the size of the chip
 * @param { Object } props.chipProps - the props of the chip (MuiChip)
 * @param { String } props.className - the class name of the chip(TailwindCss)
 * @param { boolean } props.deleteable - if the item for the chip can be deleted
 * @returns {JSX.Element}
 */
const TeamChip = ({ id, chipProps, deleteable = true }) => {
  const team = useSelector((state) => selectTeamById(state, id));

  const handleDelete = () => {
    if (deleteable) chipProps.onDelete();
  };

  return (
    <Chip
      {...chipProps}
      sx={(theme) => ({
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.contrastText,
        fontSize: 12,
        '& .MuiChip-deleteIcon': {
          color: theme.palette.secondary.contrastText,
          fontSize: 15,
        },
      })}
      onDelete={handleDelete}
      deleteIcon={deleteable ? <CancelIcon /> : <LockIcon />}
      variant="outlined"
      label={team?.name}
      id={id}
      key={id}
    />
  );
};

export default TeamChip;
