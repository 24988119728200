import i18next from 'i18next';
import { lazy } from 'react';

import authRoles from '../../../auth/authRoles';
import en from '../i18n/en';
import es from '../i18n/es';

const ShareUserLocation = lazy(() => import('./ShareUserLocation'));

i18next.addResourceBundle('en', 'authApp', en);
i18next.addResourceBundle('es', 'authApp', es);

const ShareUserLocationConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'shared-user-location/:id/',
      element: <ShareUserLocation />,
    },
  ],
};

export default ShareUserLocationConfig;
