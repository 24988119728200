import i18next from 'i18next';
import { lazy } from 'react';
import en from './i18n/en';
import es from './i18n/es';
import FormsApp from './forms/FormsApp';
import FormView from './forms/FormsSidebarContent';

const AssignmentsApp = lazy(() => import('./assignments/Assignments'));
i18next.addResourceBundle('en', 'assignmentsApp', en);
i18next.addResourceBundle('es', 'assignmentsApp', es);

const AssignmentsAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: '/ws/:wsId/assignments',
      element: <AssignmentsApp />,
      permissionsRequired: ['view_assignments_all'],
      moduleActiveRequire: 'tasks',
    },
    {
      path: '/ws/:wsId/assignments/:assignmentId',
      element: <AssignmentsApp />,
      permissionsRequired: ['view_assignments_all'],
      moduleActiveRequire: 'tasks',
    },
    {
      path: '/ws/:wsId/forms/',
      element: <FormsApp />,
      permissionsRequired: ['view_assignments_all'],
      moduleActiveRequire: 'tasks',
      children: [
        {
          path: ':formId',
          element: <FormView />,
        },
      ],
    },
  ],
};

export default AssignmentsAppConfig;
