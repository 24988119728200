/**
 * Structure of the workspace auxiliary data object.
 *
 * @typedef {Object} WorkspaceAuxData
 * @property {Object} general - General settings.
 * @property {string[]} general.default_teams_for_new_clients - List of default team IDs for new clients.
 *
 * @property {Object} app_logo - Application logo information.
 * @property {string} app_logo.base64 - Base64-encoded logo image.
 *
 * @property {Object} assignments - Assignment-related settings.
 * @property {string} assignments.status_rule - Rule for assignment status.
 * @property {boolean} assignments.title_auto_fill - Whether the title is automatically filled.
 * @property {string[]} assignments.card_body_fields - Fields to display in the assignment card body.
 * @property {string[]} assignments.create_order_fields - Fields required when creating an order.
 * @property {number} assignments.max_fields_per_form - Maximum number of fields allowed per form.
 * @property {string} assignments.title_auto_fill_format - Format for automatically filling titles.
 * @property {boolean} assignments.require_user_assigned - Whether user assignment is required.
 * @property {boolean} assignments.require_client_linked_to_user - Whether a client must be linked to a user.
 *
 * @property {Object} brand_image - Brand image details.
 * @property {string} brand_image.url - URL of the brand image.
 * @property {number} brand_image.width - Width of the brand image in pixels.
 * @property {number} brand_image.height - Height of the brand image in pixels.
 *
 * @property {Object} coordinates - Geographical coordinates.
 * @property {number} coordinates.zoom - Default zoom level.
 * @property {number} coordinates.latitude - Latitude of the location.
 * @property {number} coordinates.longitude - Longitude of the location.
 * @property {number} coordinates.accuracy_limit - Accuracy limit for coordinates.
 */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Rollbar from 'app/services/Rollbar';
import ApiRoutes from '../services/ApiRoutes';
import { changeMap } from './fuse/mapSlice';
import { getUserWorkspacePermissions } from './userWorkspacePermissionsSlice';
import Socket from '../services/Socket';
// eslint-disable-next-line import/no-cycle
import { changeLanguage } from './i18nSlice';
import { resetLocationState } from '../pages/modulesApp/locationApp/store';
// eslint-disable-next-line import/no-cycle
import { resetManagementState } from '../pages/modulesApp/managementApp/store';
import { resetSelectDataState } from './selectsData';
import { resetAssignmentsState } from '../pages/modulesApp/assignmentsApp/store';

export const accessToWorkspace = createAsyncThunk(
  'workspace/accessToWorkspace',
  async (ws, { dispatch, getState }) => {
    const { user } = getState();
    const { data } = await axios.get(ApiRoutes.workspaceEndPoint() + ws);

    if (!user.language && data.language) dispatch(changeLanguage(data.language));

    if (data.modules?.tracking?.map_types?.length > 0)
      dispatch(changeMap(data.modules.tracking.map_types[0]));

    await dispatch(getUserWorkspacePermissions(data.id)).unwrap();

    // reset map data
    resetLocationState(dispatch);
    resetManagementState(dispatch);
    resetSelectDataState(dispatch);
    resetAssignmentsState(dispatch);

    Rollbar.setWorkspace(data);

    Socket.connectWorkspace(data.shortid, user.id).then(console.log).catch(console.error);

    return data;
  }
);

export const getWorkspaceData = createAsyncThunk(
  'workspace/getWorkspaceData',
  async (ws, { dispatch, getState }) => {
    const { data } = await axios.get(ApiRoutes.workspaceEndPoint() + ws);
    return data;
  }
);

export const selectWorkspace = ({ workspace }) => workspace;

/**
 * selector to get the workspace aux data
 * @function
 * @returns {WorkspaceAuxData} Objeto con la información auxiliar del workspace.
 */
export const selectWorkspaceAux = ({ workspace }) => workspace?.aux_data;

const workspacesSlice = createSlice({
  name: 'workspace',
  initialState: {},
  reducers: {
    workspaceReset: (state, action) => ({}),
  },
  extraReducers: {
    [accessToWorkspace.fulfilled]: (state, action) => action.payload,
    [getWorkspaceData.fulfilled]: (state, action) => action.payload,
  },
});

export const moduleChecker =
  ({ workspace }) =>
  (module) =>
    Boolean(workspace?.modules[module]?.active);

export const { workspaceReset } = workspacesSlice.actions;

export default workspacesSlice.reducer;
