/* eslint-disable camelcase */
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import axios from 'axios';
import ApiRoutes from 'src/app/services/ApiRoutes';
import { t } from 'i18next';
import { v4 as uuidv4 } from 'uuid';

const clientsAdapter = createEntityAdapter({});

export const { selectAll: selectClients, selectById: selectClientById } =
  clientsAdapter.getSelectors(({ managementApp }) => managementApp.clients);

export const selectClientData = ({ managementApp }) => managementApp.clients;

const initialState = clientsAdapter.getInitialState({
  numOfPages: 0,
  count: 0,
  page: 1,
  isLoading: true,
  filtersDialog: {
    props: {
      open: false,
    },
  },
  deleteModalProps: {
    open: false,
    clientId: null,
  },
  deleteAllPlacesModalProps: {
    open: false,
  },
  locatePlaceModalProps: {
    open: false,
    placeIndex: null,
  },
  locateClientModalProps: {
    open: false,
  },
  deletePlacesModalProps: {
    open: false,
    placeIndex: null,
  },
  rightSiderbar: {
    open: false,
    tab: '',
    editMode: false,
    isLoading: true,
  },
});

/**
 * get clients from the server
 */
export const getClients = createAsyncThunk(
  'managementApp/clients/getClients',
  async ({ wsId, paramsApi }, { dispatch, getState }) => {
    dispatch(setIsLoading(true));

    const response = await axios.get(ApiRoutes.clientsEndPoint(wsId, paramsApi));

    dispatch(setIsLoading(false));
    dispatch(setNumOfPages(Math.ceil(response.data.count / 100 || 0)));
    dispatch(setCount(response.data.count));

    const data = await response.data.results;

    return data;
  }
);

export const getClient = createAsyncThunk(
  'managementApp/clients/getClient',
  async ({ wsId, clientId }, { dispatch, getState }) => {
    const { data } = await axios.get(`${ApiRoutes.clientsEndPoint(wsId) + clientId}/`);
    return data;
  }
);

/**
 * get the client places
 */
export const getClientPlaces = createAsyncThunk(
  'managementApp/clients/getClientPlaces',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {string} payload.clientId - the client id
   */
  async ({ wsId, clientId }, { dispatch, getState }) => {
    const { data } = await axios.get(`${ApiRoutes.clientsEndPoint(wsId) + clientId}/places/`);
    return { id: clientId, changes: { places_data: data } };
  }
);

/**
 * update the client places
 */
export const syncClientPlaces = createAsyncThunk(
  'managementApp/clients/syncClientPlaces',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {string} payload.clientId - the client id to update the places
   * @param {array} payload.places_data - the places data
   */
  async ({ wsId, clientId, places_data }, { dispatch, getState }) => {
    const newPlacesData = (
      await Promise.all(
        places_data.map(async (place) => {
          if (place.delete) {
            if (place.create) return null;
            await axios.delete(`${ApiRoutes.placesEndPoint(wsId)}${place.id}/`);
          } else if (place.create) {
            const { data } = await axios.post(ApiRoutes.placesEndPoint(wsId), {
              ...place,
              client: clientId,
            });

            return data;
          } else if (place.update) {
            const { data } = await axios.put(
              `${ApiRoutes.placesEndPoint(wsId)}${place.id}/`,
              place
            );

            return data;
          } else {
            return place;
          }
          return null;
        })
      )
    ).filter((e) => e !== null);

    const location_mode = newPlacesData.length > 0 ? 'through-client-places' : '';

    return { id: clientId, changes: { places_data: newPlacesData, location_mode } };
  }
);

/**
 * set the client
 */
export const setClient = createAsyncThunk(
  'managementApp/clients/setClients',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.client - the client object
   */
  async ({ wsId, client }, { rejectWithValue, dispatch, getState }) => {
    try {
      await axios.get(ApiRoutes.clientValidateUniqueFields(wsId, client));
    } catch (error) {
      let message = t('managementApp:ERROR_SAVE_CLIENT');
      if (error.response.status === 400) {
        message = t('managementApp:ERROR_CLIENT_NAME_ALREADY_EXISTS');
      } else if (error.exists_name) {
        message = t('managementApp:ALREADY_USE_NAME', { name: client.name });
      } else if (error.exists_internal_code) {
        message = t('managementApp:ALREADY_USE_INTERNAL_CODE', {
          internal_code: client.internal_code,
        });
      } else if (error.exists_invoice_tax_identifier) {
        message = t('managementApp:ALREADY_USE_INVOICE_TAX_IDENTIFIER', {
          invoice_tax_identifier: client.invoice_tax_identifier,
        });
      }

      dispatch(
        showMessage({
          message,
          variant: 'error',
        })
      );

      throw rejectWithValue(error.response.data);
    }

    try {
      const { data } = await axios.put(`${ApiRoutes.clientsEndPoint(wsId) + client.id}/`, client);
      dispatch(
        showMessage({
          message: t('managementApp:CLIENT_UPDATED', { name: client.name }),
          variant: 'success',
        })
      );

      return data;
    } catch ({ response: { data } }) {
      Object.entries(data).forEach(([field, errorData]) => {
        dispatch(
          showMessage({
            message: `${field}: ${errorData.join(', ')}`,
            variant: 'error',
          })
        );
      });

      throw rejectWithValue(data);
    }
  }
);

/**
 * add a client
 */
export const addClient = createAsyncThunk(
  'managementApp/clients/addClients',
  /**
   * @param {object} payload
   * @param {object} payload.client - the client object
   */
  async ({ client }, { rejectWithValue, dispatch, getState }) => {
    const { id: wsId } = getState().workspace;

    const id = uuidv4();
    client.id = id;

    try {
      await axios.get(ApiRoutes.clientValidateUniqueFields(wsId, client));
    } catch (error) {
      let message = t('managementApp:ERROR_SAVE_CLIENT');
      if (error.response.status === 400) {
        message = t('managementApp:ERROR_CLIENT_NAME_ALREADY_EXISTS');
      } else if (error.exists_name) {
        message = t('managementApp:ALREADY_USE_NAME', { name: client.name });
      } else if (error.exists_internal_code) {
        message = t('managementApp:ALREADY_USE_INTERNAL_CODE', {
          internal_code: client.internal_code,
        });
      } else if (error.exists_invoice_tax_identifier) {
        message = t('managementApp:ALREADY_USE_INVOICE_TAX_IDENTIFIER', {
          invoice_tax_identifier: client.invoice_tax_identifier,
        });
      }

      dispatch(
        showMessage({
          message,
          variant: 'error',
        })
      );

      throw rejectWithValue(error.response.data);
    }
    const response = await axios.post(ApiRoutes.clientsEndPoint(wsId), client);
    const data = await response.data;

    const { entities } = getState().managementApp.clients;
    const Clients = Object.keys(entities).map((key) => entities[key]);

    dispatch(
      showMessage({
        message: t('managementApp:CLIENT_CREATED', { name: data.name }),
        variant: 'success',
      })
    );

    return [data, ...Clients];
  }
);

/**
 * remove a client
 */
export const removeClient = createAsyncThunk(
  'managementApp/clients/removeClients',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.client - the client object to remove
   */
  async ({ wsId, client }, { dispatch, getState }) => {
    await axios.delete(`${ApiRoutes.clientsEndPoint(wsId) + client.id}/`);

    dispatch(
      showMessage({
        message: t('managementApp:CLIENT_DELETED', { name: client.name }),
        variant: 'success',
      })
    );

    return client.id;
  }
);

const clientsSlice = createSlice({
  name: 'managementApp/clients',
  initialState,
  reducers: {
    setNumOfPages: (state, action) => {
      state.numOfPages = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setDeleteModalProps: (state, action) => {
      state.deleteModalProps = action.payload;
    },
    setDeleteAllPlacesModalProps: (state, action) => {
      state.deleteAllPlacesModalProps = action.payload;
    },
    setFiltersModalProps: (state, action) => {
      state.filtersDialog.props = action.payload;
    },
    setlocatePlaceModalProps: (state, action) => {
      state.locatePlaceModalProps = action.payload;
    },
    setlDeletePlaceModalProps: (state, action) => {
      state.deletePlacesModalProps = action.payload;
    },
    setRightSidebarProps: (state, action) => {
      state.rightSiderbar = { ...state.rightSiderbar, ...action.payload };
    },
    resetClientsState: () => initialState,
  },
  extraReducers: {
    [getClients.fulfilled]: clientsAdapter.setAll,
    [getClient.fulfilled]: clientsAdapter.setOne,
    [getClientPlaces.fulfilled]: clientsAdapter.updateOne,
    [setClient.fulfilled]: clientsAdapter.setOne,
    [syncClientPlaces.fulfilled]: clientsAdapter.updateOne,
    [addClient.fulfilled]: clientsAdapter.setAll,
    [removeClient.fulfilled]: clientsAdapter.removeOne,
  },
});

export const selectSelectedClient = ({ managementApp }) => managementApp.clients.selectedClientId;

export const {
  setNumOfPages,
  setIsLoading,
  setPage,
  resetClientsState,
  setCount,
  setDeleteModalProps,
  setFiltersModalProps,
  setRightSidebarProps,
  setlocatePlaceModalProps,
  setlocateClientModalProps,
  setlDeletePlaceModalProps,
  setDeleteAllPlacesModalProps,
} = clientsSlice.actions;

export default clientsSlice.reducer;
