/**
 * this file will contain the translations for the settingsApp page in spanish
 */
const locale = {
  ACCURACY_LIMIT: 'Límite de precisión',
  ACCURACY_LIMIT_DESP:
    'Maxima exactitud en metros de los registros GPS que se muestran en los mapas.',
  ASSIGNMENTS: 'Tareas',
  AUTO_START: 'Auto encendido ',
  AUTO_START_DESP:
    'Define un cronograma para iniciar y detener automáticamente la ubicación en tiempo real a las horas configuradas',
  AUTOMATIC_SYNCHRONIZATION_THRESHOLD: 'Umbral de sincronización automática',
  AUTOMATIC_SYNCHRONIZATION_THRESHOLD_DESP:
    'El número mínimo de registros persistentes que se debe acumular antes de activar una acción de sincronización automática.',
  CARD_BODY_FIELDS: 'Orden de los datos en el listado de tareas',
  CARD_BODY_FIELDS_DESP:
    'Puede indicar el orden de los datos que se mostraran en la pantalla de tareas.',
  CLIENT: 'Cliente',
  CLIENT_NAME: 'Nombre del cliente',
  COUNTRY: 'País',
  CREATE_ORDER_FIELDS: 'Orden de los campos en la creación de una tarea ',
  CREATE_ORDER_FIELDS_DESP:
    'Puede indicar el orden de los campos en la pantalla donde se crean las tareas.',
  DATE: 'Fecha',
  DATE_DUE: 'Fecha de vencimiento',
  DAYS_APPLY_AUTO_START: 'Días que aplica el auto encendido',
  DAYS_APPLY_AUTO_START_DESP:
    'Indique que días va aplicar el autoencendido de la ubicación en tiempo real.',
  DESCRIPTION: 'Descripción',
  DISTANCE_FILTER: 'Filtro de distancia',
  DISTANCE_FILTER_DESP:
    'La distancia mínima (medida en metros) que un dispositivo debe moverse horizontalmente antes de que se genere un evento de actualización',
  FORM_CONFIG: 'Configuración para formularios',
  FORM_NAME: 'Nombre del formulario',
  FORMAT_TITLE: 'Formato del título',
  FORMAT_TITLE_DESP: 'Indique el formato a seguir para autogenerar el título.',
  FORMS: 'Formularios',
  GENERAL: 'General',
  HIDE_BUTTON_LOGOUT: 'Ocultar botón para cerrar sesión',
  HOUR: 'Hora',
  INITIAL_POSITION_MAP: 'Posicion inicial del mapa',
  LANGUAGE: 'Idioma',
  LATITUDE: 'Latitud',
  LONGITUDE: 'Longitud',
  MAX_FIELDS_IN_WORKSPACE:
    'Cantidad máxima de campo por escritorio. Si deseas aumentar la cantidad puedes contactarnos',
  MAX_FIELDS_PER_FORM:
    'Número máximo de campos por formulario. Si deseas aumentar la cantidad puedes contactarnos',
  MAXIMUM_DAYS_PERSIST: 'Máximo días para persistir',
  MAXIMUM_DAYS_PERSIST_DESP:
    'Número máximo de días para almacenar una geolocalización en la base de datos local del teléfono cuando no hay conexión al servidor (offline mode).',
  MAXIMUM_GEOFENCE_ALLOWED: 'Máxima cantidad de geocercas permitidas',
  MAXIMUM_GEOFENCE_ALLOWED_DESP:
    'Esta es la cantidad máxima de Geocercas que se pueden crear en este Escritorio. Si deseas aumentar la cantidad puedes contactarnos.',
  MAXIMUM_RECORDS_PERSIST: 'Máximo de registros para persistir',
  MAXIMUM_RECORDS_PERSIST_DESP:
    'Número máximo de registros para persistir en la base de datos local del teléfono cuando no hay conexión al servidor.',
  MOBILE_APP_SETTINGS: 'Configuración de aplicación móvil',
  NAME: 'Nombre',
  NAME_CANNOT_EMPTY: 'El nombre no puede estar vacío.',
  OBSERVATION: 'Observación',
  PROJECT: 'Proyecto',
  REVERSE_GEOCODING: 'Geocodificación inversa',
  REVERSE_GEOCODING_DESP:
    'Permite obtener direcciones en lenguaje natural a partir de coordenadas geográficas. Si deseas activar la funcionalidad puedes contactarnos.',
  SAVE: 'Guardar',
  SAVED_CONFIGURATION: 'Configuración actualizada',
  SELECT_INITIAL_POSITION_MAP: 'Selecciona la posición inicial del mapa.',
  STATUS_RULE_DESP:
    'El estado de las tareas cambia automáticamente conforme se editan los campos de la tarea.',
  STATUS_RULE_TITLE: 'Cambio de estado automático',
  TAGS: 'Etiqueta',
  TASK_MODULE_DESP: 'Controla si desea habilitar o deshabilitar el módulo de tareas.',
  TASK_MODULE_TITLE: 'Módulo de tareas',
  TIME_ZONE: 'Zona horaria',
  TITLE: 'Título',
  TITLE_AUTO_FILL_DESP: 'Autogenerar el título de la tarea siguiendo un formato indicado.',
  TITLE_AUTO_FILL_TITLE: 'Autocompletar título de tareas',
  TRACKING: 'Ubicación',
  TRACKING_MODULE_DESP: 'Controla si desea habilitar o deshabilitar el módulo de Ubicación.',
  TRACKING_MODULE_TITLE: 'Módulo de Ubicación',
  USER: 'usuario',
  USER_INTERFACE: 'Interfaz de usuario',
  VALID_NUM_VALUE:
    'Número no válido ,Valores validos entre {{min}} y {{max}}, por defecto es {{default}}',
  ZOOM: 'Zoom',
  REQUIRE_USER_ASSIGNMENT_TITLE: 'Requerir asignación de usuario',
  REQUIRE_USER_ASSIGNMENT_DESP:
    'Si esta opción está activada, será obligatorio asignar un usuario a una tarea.',
  REQUIRE_CLIENT_LINKED_TO_USER_TITLE: 'Requerir clientes de usuario asignado',
  REQUIRE_CLIENT_LINKED_TO_USER_DESP:
    'Si esta opción está activada, las tareas solo se podrán vincular al cliente que el usuario asignado pueda atender.',
  DEFAULT_TEAMS_FOR_NEW_CLIENTS_TITLE: 'Asignación automática de equipos a clientes',
  DEFAULT_TEAMS_FOR_NEW_CLIENTS_DESP:
    'Al crear clientes, asigna automáticamente los equipos especificados.',
};

export default locale;
