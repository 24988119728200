import FuseLoading from '@fuse/core/FuseLoading';
import FuseUtils from '@fuse/utils';
import settingsConfig from 'app/configs/settingsConfig';
import { Navigate, useParams } from 'react-router-dom';
import { selectUser } from 'app/store/userSlice';
import { useSelector } from 'react-redux';

import authAppConfig from '../pages/authApp/authAppConfig';
import dashboardsConfigs from '../pages/dashboardApp/dashboardsConfigs';
import modulesAppConfigs from '../pages/modulesApp/modulesAppConfigs';
import errorAppConfig from '../pages/errorApp/errorAppConfig';
import ShareDataAppConfig from '../pages/sharedDataApp/ShareDataConfig';
import OrganizationsAppConfig from '../pages/organizationsApp/OrganizationsAppConfig';
import WorkspacesAppConfig from '../pages/workspacesApp/WorkspacesAppConfig';
import { authRoles } from '../auth';

/**
 * This file is the main configuration file for the app's routes.
 */

/**
 * This is an array of route configurations.
 * Each route configuration must have the following properties:
 *  path : the url path of the route
 *  element: react component to render
 *  roleRequired: the role required to access the route (optional)
 */
const routeConfigs = [
  OrganizationsAppConfig,
  WorkspacesAppConfig,
  errorAppConfig,
  dashboardsConfigs,
  ...modulesAppConfigs,
  ...authAppConfig,
  ...ShareDataAppConfig,
];

/**
 * element to redirect to the 404 page dinamically
 * @returns redirect to the 404 page corresponding to the workspace
 */
const ReDirect404 = () => {
  const { wsId } = useParams();

  if (wsId) {
    return <Navigate to={`ws/${wsId}/pages/error/404/`} replace />;
  }
  if (window.location.pathname.split('/')[2]) {
    return (
      <Navigate to={`ws/${window.location.pathname.split('/')[2]}/pages/error/404/`} replace />
    );
  }
  return <Navigate to="error-404/" replace />;
};

/**
 * element to redirect to the correct page depending on the user role using react component
 * @returns redirect to the correct page depending on the user role
 */
const ReDirect = () => {
  const user = useSelector(selectUser);

  if (authRoles.org.includes(user.role)) {
    return <Navigate to={`/org/${user.organizations[0].id}/`} replace />;
  }
  if (authRoles.platform.includes(user.role)) {
    return <Navigate to="organizations" replace />;
  }
  return <Navigate to="workspaces" replace />;
};

/**
 * This is the main array of routes for the app.
 * It is generated from the routeConfigs array and the defaultAuth property of the settingsConfig object.
 */
const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '/',
    element: <ReDirect />,
    roleRequired: settingsConfig.defaultAuth,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '*',
    element: <ReDirect404 />,
  },
];

export default routes;
