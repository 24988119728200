import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { es, enUS } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      'Welcome to React': 'Welcome to React and react-i18next',
    },
  },
};

const onInit = () => {
  let language;
  const languageBrowser = window.navigator.language.toLowerCase();

  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get('lang');

  if (lang === 'es') {
    language = 'es';
    setDefaultOptions({ locale: es });
  } else if (languageBrowser.includes('es')) {
    language = 'es';
    setDefaultOptions({ locale: es });
  } else {
    language = 'en';
    setDefaultOptions({ locale: enUS });
  }

  return {
    resources,
    lng: language,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  };
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(onInit());

export default i18n;
