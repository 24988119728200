/* eslint-disable camelcase */
import ApiRoutes from 'src/app/services/ApiRoutes';
import axios from 'axios';
import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import { t } from 'i18next';

const assignmentsAdapter = createEntityAdapter({});

export const selectAssignmentsData = ({ assignmentsApp }) => assignmentsApp.assignments;

export const {
  selectAll: selectAssignments,
  selectEntities: selectAssignmentsEntities,
  selectById: selectAssignmentsById,
} = assignmentsAdapter.getSelectors(selectAssignmentsData);

/**
 * get the assignments
 */
export const getAssignments = createAsyncThunk(
  'assignmentsApp/assignments/getAssignments',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.params - the params to filter the assignments
   */
  async ({ wsId, params }, { getState, dispatch }) => {
    await dispatch(setIsLoading(true));
    const { data } = await axios.get(ApiRoutes.assignmentsEndPoint(wsId, params));
    dispatch(setPage(params.page));
    dispatch(setNumOfPages(Math.ceil(data.count / 100 || 0)));
    dispatch(setCount(data.count));
    await dispatch(setIsLoading(false));
    return data.results;
  }
);

/**
 * get a assignment
 */
export const getAssignment = createAsyncThunk(
  'assignmentsApp/assignments/getAssignment',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {string} payload.id - the assignment id
   */
  async ({ wsId, id }, { getState }) => {
    const { entities } = getState().assignmentsApp.assignments;

    if (entities[id]) return entities[id];

    const { data } = await axios.get(ApiRoutes.assignmentsEndPoint(wsId) + id);

    return data;
  }
);

/**
 * get the full data of a assignment
 */
export const getFullDataAssignment = createAsyncThunk(
  'assignmentsApp/assignments/getFullDataAssignment',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {string} payload.id - the assignment id to get the full data
   */
  async ({ wsId, id }, { getState, dispatch }) => {
    // await dispatch(setIsLoadingSidebar(true));

    const { data } = await axios.get(ApiRoutes.assignmentsEndPoint(wsId) + id);

    // await dispatch(setIsLoadingSidebar(false));

    return { id, changes: { ...data, fullData: true } };
  }
);

/**
 * set a assignment
 */
export const setAssignment = createAsyncThunk(
  'assignmentsApp/assignments/setAssignment',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.assignment - the assignment data
   */
  async ({ wsId, assignment }, { getState, dispatch }) => {
    await dispatch(setIsLoadingSidebar(true));

    try {
      const { data } = await axios.put(`${ApiRoutes.assignmentsEndPoint(wsId) + assignment.id}/`, {
        title: assignment.title,
        user: assignment.user,
        status: assignment.status,
        description: assignment.description,
        observation: assignment.observation,
        forms: assignment.forms,
        project: assignment.project,
        client: assignment.client,
        place: assignment.place,
        date_due: assignment.date_due,
        tags: assignment.tags,
      });

      await dispatch(setIsLoadingSidebar(false));

      return { id: assignment.id, changes: data };
    } catch ({ response: { data } }) {
      await dispatch(setIsLoadingSidebar(false));
      dispatch(showMessage({ message: t(`assignmentApp:${data.title[0]}`), variant: 'error' }));
      throw new Error(':(');
    }
  }
);

/**
 * share a assignment
 */
export const shareAssignment = createAsyncThunk(
  'assignmentsApp/assignments/shareAssignment',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.assignmentId - the assignment data
   * @param {object} payload.expireTime - the expire time of the shared link
   *
   */
  async ({ wsId, assignmentId, expireTime }, { getState, dispatch }) => {
    const { data } = await axios.put(
      `${ApiRoutes.assignmentsEndPoint(wsId) + assignmentId}/share/`,
      {
        expire_time: expireTime,
      }
    );

    dispatch(setShareAssignmentData(data));
  }
);

/**
 * duplicate a assignment
 */
export const duplicateAssignment = createAsyncThunk(
  'assignmentsApp/assignments/duplicateAssignment',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {string} payload.assignmentId - the assignment id to duplicate
   * @param {string} payload.newAssignmentId - the new assignment id with the duplicated data
   */
  async ({ wsId, assignmentId, newAssignmentId }, { getState, dispatch }) => {
    await dispatch(setIsLoadingSidebar(true));

    try {
      const { data } = await axios.put(
        `${ApiRoutes.assignmentsEndPoint(wsId) + assignmentId}/duplicate/`,
        {
          new_id: newAssignmentId,
        }
      );

      await dispatch(setIsLoadingSidebar(false));

      const { entities } = getState().assignmentsApp.assignments;
      const assignments = Object.keys(entities).map((key) => entities[key]);

      return [data, ...assignments];
    } catch ({ response: { data } }) {
      await dispatch(setIsLoadingSidebar(false));
      dispatch(showMessage({ message: t(`assignmentApp:${data.title[0]}`), variant: 'error' }));
      throw new Error(':(');
    }
  }
);

/**
 * create a new assignment
 */
export const addAssignment = createAsyncThunk(
  'assignmentsApp/assignments/addAssignment',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {object} payload.assignment - the assignment data
   */
  async ({ wsId, assignment }, { getState, dispatch }) => {
    await dispatch(setIsLoadingSidebar(true));

    try {
      const { data } = await axios.post(ApiRoutes.assignmentsEndPoint(wsId), {
        id: assignment.id,
        user: assignment.user,
        title: assignment.title,
        status: assignment.status,
        description: assignment.description,
        observation: assignment.observation,
        forms: assignment.forms,
        project: assignment.project,
        client: assignment.client,
        place: assignment.place,
        date_due: assignment.date_due,
        tags: assignment.tags,
      });

      await dispatch(setIsEditingSidebar(false));
      await dispatch(setIsLoadingSidebar(false));
      const { entities } = getState().assignmentsApp.assignments;
      const assignments = Object.keys(entities).map((key) => entities[key]);

      return [data, ...assignments];
    } catch ({ response: { data } }) {
      await dispatch(setIsLoadingSidebar(false));
      dispatch(showMessage({ message: t(`assignmentApp:${data.title[0]}`), variant: 'error' }));
      throw new Error(':(');
    }
  }
);

/**
 * reassign a assignment to another user or a none user
 */
export const reassignAssignment = createAsyncThunk(
  'assignmentsApp/assignments/addAssignment',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {string} payload.id - the assignment id to reassign
   * @param {object} payload.reassignData - the data to reassign the assignment
   */
  async ({ wsId, id, reassignData }, { getState, dispatch }) => {
    try {
      const { data } = await axios.put(
        `${ApiRoutes.assignmentsEndPoint(wsId) + id}/change-user/`,
        reassignData
      );

      const { entities } = getState().assignmentsApp.assignments;
      const assignments = Object.keys(entities).map((key) => {
        if (key === id) return data;
        return entities[key];
      });

      // return [data, ...assignments];

      return assignments;
    } catch ({
      response: {
        data: { error },
      },
    }) {
      dispatch(showMessage({ message: t(`${error}`), variant: 'error' }));
      throw new Error(':(');
    }
  }
);

/**
 * delete a assignment
 */
export const deleteAssignment = createAsyncThunk(
  'assignmentsApp/assignments/deleteAssignment',
  /**
   * @param {object} payload
   * @param {string} payload.wsId - the workspace id
   * @param {string} payload.assignmentId - the assignment id to delete
   */
  async ({ wsId, assignmentId }, { getState, dispatch }) => {
    await dispatch(setIsLoadingSidebar(true));

    await axios.delete(`${ApiRoutes.assignmentsEndPoint(wsId) + assignmentId}/`);
    await dispatch(setIsLoadingSidebar(false));

    return assignmentId;
  }
);

const initialState = assignmentsAdapter.getInitialState({
  isLoading: true,
  rightSidebarProps: {
    isLoading: false,
    isEditing: false,
  },
  numOfPages: 0,
  count: 0,
  page: 1,
  filterByDateProps: {
    open: false,
  },
  filterByClientProps: {
    open: false,
  },
  removeFiltersConfirmProps: {
    open: false,
  },
  deleteAssignmentConfirmProps: {
    open: false,
    assignmentId: null,
  },
  duplicateAssignmentConfirmProps: {
    open: false,
    assignmentId: null,
  },
  appliedFiltersProps: {
    open: false,
  },
  selectClientProps: {
    open: false,
    screen: null,
  },
  selectUserProps: {
    open: false,
  },
  shareAssignmentProps: {
    open: false,
    assignmentId: null,
    sharedData: null,
  },
});

const asssignmentsSlice = createSlice({
  name: 'assignmentsApp/assignments',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsLoadingSidebar: (state, action) => {
      state.rightSidebarProps.isLoading = action.payload;
    },
    setIsEditingSidebar: (state, action) => {
      state.rightSidebarProps.isEditing = action.payload;
    },
    setFilterByDateProps: (state, action) => {
      state.filterByDateProps = action.payload;
    },
    setFilterByClientProps: (state, action) => {
      state.filterByClientProps = action.payload;
    },
    setNumOfPages: (state, action) => {
      state.numOfPages = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setCount: (state, action) => {
      state.count = action.payload;
    },
    openRemoveFiltersConfirmModal: (state, action) => {
      state.removeFiltersConfirmProps = {
        open: true,
      };
    },
    closeRemoveFiltersConfirmModal: (state, action) => {
      state.removeFiltersConfirmProps = {
        open: false,
      };
    },
    openDeleteAssignmentConfirmModal: (state, action) => {
      state.deleteAssignmentConfirmProps = {
        open: true,
        assignmentId: action.payload,
      };
    },
    closeDeleteAssignmentConfirmModal: (state, action) => {
      state.deleteAssignmentConfirmProps = {
        open: false,
        assignmentId: null,
      };
    },
    openDuplicateAssignmentConfirmModal: (state, action) => {
      state.duplicateAssignmentConfirmProps = {
        open: true,
        assignmentId: action.payload,
      };
    },
    closeDuplicateAssignmentConfirmModal: (state, action) => {
      state.duplicateAssignmentConfirmProps = {
        open: false,
        assignmentId: null,
      };
    },
    openShareAssignmentModal: (state, action) => {
      state.shareAssignmentProps = {
        open: true,
        assignmentId: action.payload,
        sharedData: null,
      };
    },
    closeShareAssignmentModal: (state, action) => {
      state.shareAssignmentProps = {
        open: false,
        assignmentId: null,
        sharedData: null,
      };
    },
    setShareAssignmentData: (state, action) => {
      state.shareAssignmentProps.sharedData = action.payload;
    },
    openAppliedFiltersModal: (state, action) => {
      state.appliedFiltersProps = {
        open: true,
      };
    },
    closeAppliedFiltersModal: (state, action) => {
      state.appliedFiltersProps = {
        open: false,
      };
    },
    openSelectClientModal: (state, action) => {
      state.selectClientProps = {
        open: true,
        screen: action.payload,
      };
    },
    closeSelectClientModal: (state, action) => {
      state.selectClientProps = {
        open: false,
        screen: null,
      };
    },
    setScreenSelectClientModal: (state, action) => {
      state.selectClientProps.screen = action.payload;
    },
    openSelectUserModal: (state, action) => {
      state.selectUserProps = {
        open: true,
      };
    },
    closeSelectUserModal: (state, action) => {
      state.selectUserProps = {
        open: false,
      };
    },
    resetAssignments: () => initialState,
  },
  extraReducers: {
    [getAssignments.fulfilled]: assignmentsAdapter.setAll,
    [getAssignment.fulfilled]: assignmentsAdapter.addOne,
    [duplicateAssignment.fulfilled]: assignmentsAdapter.setAll,
    [setAssignment.fulfilled]: assignmentsAdapter.updateOne,
    [addAssignment.fulfilled]: assignmentsAdapter.setAll,
    [getFullDataAssignment.fulfilled]: assignmentsAdapter.updateOne,
    [deleteAssignment.fulfilled]: assignmentsAdapter.removeOne,
    [reassignAssignment.fulfilled]: assignmentsAdapter.setAll,
  },
});

export const {
  closeAppliedFiltersModal,
  closeDeleteAssignmentConfirmModal,
  closeDuplicateAssignmentConfirmModal,
  closeRemoveFiltersConfirmModal,
  closeSelectClientModal,
  closeSelectUserModal,
  closeShareAssignmentModal,
  openAppliedFiltersModal,
  openDeleteAssignmentConfirmModal,
  openDuplicateAssignmentConfirmModal,
  openRemoveFiltersConfirmModal,
  openSelectClientModal,
  openSelectUserModal,
  openShareAssignmentModal,
  resetAssignments,
  setCount,
  setShareAssignmentData,
  setFilterByClientProps,
  setFilterByDateProps,
  setIsEditingSidebar,
  setIsLoading,
  setIsLoadingSidebar,
  setNumOfPages,
  setPage,
  setScreenSelectClientModal,
} = asssignmentsSlice.actions;

export default asssignmentsSlice.reducer;
