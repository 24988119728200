/**
 * this file will contain the translations for the Spanish language
 */
const locale = {
  ACCEPT: 'Aceptar',
  ACCURACY: 'Exactitud',
  ACCURACY_MEASUREMENT: 'Exactitud de medición: ~ {{accuracy}} metros',
  ACTIVE_TODAY: 'Con actividad hoy',
  ADD_FILTER_TO_SHOW_FILTERS: 'Agrega filtros por rango de fecha, etiquetas o usuario',
  ADD_ROUTE: 'Añadir recorrido',
  ADD_ROUTE_TO_SHOW_ROUTES: 'Agrega recorridos filtrados por rango de fechas',
  ADDRESS: 'Dirección',
  ALERT_APPLIED: 'Alerta aplicada',
  ALERT_APPLIES_TO_TEAMS: 'La alerta aplica para los siguientes equipos',
  ALERT_APPLIES_TO_USERS: 'La alerta aplica para los siguientes usuarios',
  ALERT_DETAILS: 'Detalles de la alerta',
  ALERT_FOR_ALL: 'La alerta es para todos los usuarios',
  ALERTS: 'Alertas',
  APP_VERSION: 'Versión de la aplicación',
  APPLY: 'Aplicar',
  APPLY_FILTERS: 'Aplicar Filtros',
  ARE_SURE_REMOVE_FILTERS: '¿Esta seguro de remover todos los filtros?',
  ARE_SURE_REMOVE_GEOFENCE: '¿Esta seguro de remover la Geocerca "{{geofence}}"?',
  ARE_SURE_REMOVE_ROUTES:
    '¿Está seguro de remover todos los filtros aplicados para el usuario {{user}}?',
  ARE_SURE_SAVE_GEOFENCE:
    '¿Está seguro de que desea guardar esta geocerca sin asignarle una alerta?',
  ARRIVE_DATE: 'Fecha de entrada',
  ASSIGNMENTS: 'Tareas',
  AUXILIARY_OFFICE: 'Oficina auxiliar',
  AVAILABLE: 'Disponibles',
  AVERAGE_SPEED: 'Velocidad promedio',
  BRANCH_OFFICE: 'Sucursal',
  CANCEL: 'Cancelar',
  CHANGE_COLOR: 'Cambiar color',
  CHARGING: 'Cargando',
  CHECK_IN_OUT_DATE: 'Fecha de visita',
  CHECK_IN_OUTS: 'Visitas',
  CHECK_IN_OUTS_TO_GEOFENCES: 'Visitas a Geocercas',
  CLIENT: 'Cliente',
  CLIENTS: 'Clientes',
  CONSTRUCTION_SITES: 'Sitios de construcción',
  COORDINATES: 'Coordenadas',
  COORDINATES_COPIED_TO_CLIPBOARD: 'Coordenadas copiadas al portapapeles',
  COPY: 'Copiar',
  COPY_PASTE_EXCEL: 'Copie y pegue sus coordenadas desde un libro de Excel o una hoja de cálculo.',
  CREATE: 'Crear',
  CREATE_A_NEW_ALERT: 'Crear una nueva alerta',
  CREATE_GEOFENCE_HERE: 'Crear geocerca aquí',
  CREATE_POINT_HERE: 'Crear un punto aquí',
  CREATED: 'Creada',
  CREATION_DATE: 'Fecha de creación',
  CUSTOMIZE_FILTERS: 'Personalizar filtros',
  DAILY_RECORD: 'Informe Diario',
  DAILY_REPORT: 'Reporte Diario',
  DATE: 'Fecha',
  DATE_CREATED: 'Fecha de creación',
  DATE_DONE: 'Fecha completada',
  DATE_DUE: 'Fecha de vencimiento',
  DATE_END: 'Hasta',
  DATE_EVENT: 'Fecha del evento',
  DATE_START: 'Desde',
  DATE_STATUS: 'Fecha de cambio de estado',
  DELETE_FIGURE: 'Borrar figura',
  DELETE_POINT: 'Eliminar Punto',
  DEPARTURE_DATE: 'Fecha de Salida',
  DESCRIPTION: 'Descripción',
  DETAILS: 'Detalles',
  DISTANCE: 'Distancia',
  DISTANCE_AUX_ONE: 'Distancia auxiliar',
  DISTANCE_TRAVELED: 'Distancia viajada: {{distance}} Kilómetros',
  DISTANCE_TRAVELED_IN_THIS_POINT: 'En este punto había recorrido {{km}} Km ese día.',
  DISTANCE_TRAVELED_POINT: 'Distancia recorrida en este punto',
  DONE_TODAY: 'Completadas hoy',
  DOWNLOAD_CSV_FILE: 'Descargar archivo CSV',
  DOWNLOAD_ERROR: 'Error de Descarga',
  DOWNLOAD_XLSX_FILE: 'Descargar archivo Excel',
  DRAW_CIRCLE: 'Dibujar circulo',
  DRAW_HANDLERS_CIRCLE_RADIUS: 'Radio',
  DRAW_HANDLERS_CIRCLE_TOOLTIP_START: 'Haz click y arrastra para dibujar un círculo.',
  DRAW_HANDLERS_POLYGON_TOOLTIP_CONT: 'Haz click para seguir dibujando el polígono',
  DRAW_HANDLERS_POLYGON_TOOLTIP_END: 'Haz click en el primer punto para cerrar el polígono.',
  DRAW_HANDLERS_POLYGON_TOOLTIP_START: 'Haz click para comenzar a dibujar el polígono.',
  DRAW_HANDLERS_SIMPLESHAPE_TOOLTIP_END: 'Suelta el mouse para finalizar el circulo.',
  DRAW_POLYGON: 'Dibujar polígono',
  DRAW_TOOLBAR_ACTIONS_TEXT: 'Cancelar',
  DRAW_TOOLBAR_ACTIONS_TITLE: 'Cancelar dibujo',
  DRAW_TOOLBAR_BUTTONS_CIRCLE: 'Dibujar un circulo',
  DRAW_TOOLBAR_BUTTONS_POLYGON: 'Dibujar un polígono',
  DRAW_TOOLBAR_FINISH_TEXT: 'Finalizar',
  DRAW_TOOLBAR_FINISH_TITLE: 'Finalizar dibujo',
  DRAW_TOOLBAR_UNDO_TEXT: 'Eliminar el último punto',
  DRAW_TOOLBAR_UNDO_TITLE: 'Eliminar el último punto dibujado',
  DURATION: 'Duración',
  EDIT: 'Editar',
  EDITION_DATE: 'Fecha de edición',
  EMAIL_VALID: 'Ingrese un correo electrónico válido',
  ENTRY_DATE: 'Fecha de Entrada',
  ERROR_SEARCH:
    'Indique que desea buscar utilizando la palabras "usuario" o "geocerca". Por ejemplo "usuario: Carl Sagan" o "usuario: carl.sagan@cosmos.com" o "geocerca: nombre de una geocerca"',
  FACTORY: 'Fábrica',
  FAKE_GPS: 'GPS falso',
  FILTER_ALERTS: 'Filtrar Alertas',
  FILTER_ALERTS_MAX_SPEED: 'Se filtrarán alertas de velocidad máxima',
  FILTER_ASSIGNMENTS: 'Filtrar tareas',
  FILTER_BY_TAGS: 'Filtrar por etiquetas',
  FILTER_CHECK_IN_OUTS: 'Filtrar visitas',
  FILTER_DAILY_RECORD: 'Filtrar informes diarios',
  FILTER_GEOFENCES_RECORDS: 'Filtrar entradas y salidas',
  FILTER_ROUTES: 'Filtrar recorridos',
  FILTER_TO_SHOW_ALERTS: 'Filtra alertas por rango de fecha o usuario',
  FILTER_TO_SHOW_ASSIGNMENTS: 'Filtra tareas por usuario, etiquetas, rango de fechas y cliente',
  FILTER_TO_SHOW_DAILY_RECORD: 'Filtra informes diarios por rango de fecha y, usuario o grupo',
  FILTER_TO_SHOW_GEOFENCES_RECORDS:
    'Filtra por geocercas, usuarios y fecha para mostrar emtradas y salidas ',
  FILTERS_APPLIED: 'Filtros aplicados',
  FROM_DATE_ASSIGNMENTS: 'Tareas del {{date}}',
  FROM_DATE_CHECK_IN_OUTS: 'Visitas del {{date}}',
  FROM_DATE_GEOFENCES: 'Geocercas del {{date}}',
  FROM_DATE_REPORTS: 'Reportes del {{date}}',
  FROM_DATE_USER_LOCATION_REGISTER: 'Recorrido del {{date}}',
  Fulfillments: 'Cumplimientos',
  GEO_ALERT: 'Alerta geográfica',
  GEOFENCE: 'Geocerca',
  GEOFENCE_NAME: 'Nombre de la geocerca',
  GEOFENCE_WITHOUT_ALERT: 'Geocerca sin alerta',
  GEOFENCES: 'Geocercas',
  HEADQUARTERS: 'Sede',
  HIDE_FIGURE: 'Ocultar Figura',
  HIDE_MARKER: 'Ocultar marcador',
  HIDE_MEASUREMENT_POINT: 'Ocultar puntos de medición',
  HIDE_ON_MAP: 'Ocultar en el mapa',
  HIDE_PATH: 'Ocultar Recorrido',
  HIDE_ROUTE_OF_THIS_DAY: 'Ocultar recorrido de este dia',
  IN_VEHICLE: 'Moviéndose en vehículo',
  INPUTS_OUTPUTS: 'Entradas y salidas',
  INPUTS_OUTPUTS_GEOFENCES: 'Entradas y salidas de geocercas',
  INTERNAL_CODE: 'Código interno',
  INVALID_DATE: 'Fecha Invalida',
  INVOICE_TAX: 'Código tributario',
  IS_MOVING: 'Está moviendo',
  IS_STOPPED: 'Está detenido',
  JUST_MAP: 'Solo el mapa',
  LATITUDE: 'Latitud',
  LEAVE_DATE: 'Fecha de salida',
  LEVEL_BATTERY: 'Nivel de batería: {{level}}% ',
  LIST_POINT: 'Lista de puntos',
  LOCATE_ON_MAP: 'Localizarlo en el mapa',
  LOCATION: 'Ubicación',
  LONGITUDE: 'Longitud',
  MANAGE_USERS_ON_THE_MAP: 'Gestionar usuarios en el mapa',
  MAP: 'Mapa',
  MAP_SETTING: 'Configuración del mapa',
  MAP_TYPE: 'Tipo de Mapa',
  MAX_SPEED: 'Velocidad máxima: {{speed}} Km/h',
  MAXIMUM_SPEED: 'Velocidad máxima',
  MEASUREMENT_DATE: 'Fecha de Medición',
  NAME: 'Nombre',
  NAME_CANNOT_EMPTY: 'El campo nombre no puede estar vacío',
  NO_ACTIVE_TODAY: 'Sin actividad hoy',
  NO_ACTIVE_TWO_DAYS: 'Sin actividad más de dos dias',
  NO_ALERTS_FOUND: 'No se encontraron Alertas',
  NO_ASSIGNMENTS_FOUND: 'Tareas no encontradas',
  NO_CHECK_IN_OUTS_FOUND: 'No se encontraron visitas',
  NO_CLIENT_SELECTED: 'Ningún cliente seleccionado',
  NO_CLIENTS_TO_SHOW: 'No hay clientes que mostrar',
  NO_GEOFENCES_FOUND: 'No se encontraron geocercas',
  NO_GEOFENCES_TO_SHOW: 'no hay geocercas que mostrar',
  NO_MEASUREMENTS_FOR_THESE_DATES: 'No hubo actividades por estas fechas ({{dates}})',
  NO_OPTION: 'Sin opciones',
  NO_TRACKING_USER_TODAY: '{{name}} no ha registrado actividad el dia de hoy',
  NO_TRACKING_USER: '{{name}} no ha registrado actividades por estas fechas ({{dates}})',
  NO_USERS_FOUND: 'No se encontraron usuarios',
  NO_USERS_TO_SHOW: 'No hay usuarios que mostrar',
  NO_VEHICLES_FOUND: 'No se encontraron vehículos',
  NOTIFICATION_BE_SEND_EMAILS: 'Se enviará una notificación a los siguientes correos',
  NOTIFY_EMAIL: 'Notificar por correo electrónico',
  NOTIFY_PLATFORM: 'Notificar en OptaCheck',
  OFF: 'Apagada',
  'OFF/ON': 'Apagada/Encendida',
  ON: 'Encendida',
  ON_BICYCLE: 'Moviéndose en bicicleta',
  ON_FOOT: 'Moviéndose a pie',
  ONLY_DONE_ASSIGNMENT_LOCATED: 'Solo se pueden localizar tareas con estado "Completa"',
  OTHERS: 'Otros',
  PARKING_LOT: 'Estacionamiento',
  PERMISSION_DENIED: 'No tienes el permiso necesario para usar esa funcionalidad',
  PLACES: 'Lugares',
  POINT_TO_SHOW: 'Puntos a mostrar en el mapa',
  REMOVE: 'Remover',
  REMOVE_FILTER: 'Remover Filtro',
  REMOVE_FILTERS: 'Remover filtros',
  REMOVE_GEOFENCE: 'Remover Geocerca',
  REMOVE_ROUTES: 'Remover recorridos',
  ROUTES: 'Recorridos',
  RUNNING: 'Corriendo',
  SAVE: 'Guardar',
  SEARCH: 'Buscar elementos en OptaCheck',
  SEARCH_PLACE: 'Busca lugares en el mundo',
  SEE_CLIENTS: 'Ver clientes',
  SEE_CLIENTS_WITHOUT_LOCATION: 'Ver clientes sin ubicación',
  SELECT_CLIENT: 'Seleccionar cliente',
  SELECT_USER_TO_SHOW_ROUTES: 'Selecciona un usuario para mostrar sus recorridos',
  SELECT_USERS_GEOFENCES: 'Seleccionar usuarios y geocercas',
  SELLER: 'Vendedor',
  SHOW_CHECK_IN_OUT: 'Mostrar Visita',
  SHOW_CHECK_IN_OUTS: 'Mostrar visitas',
  SHOW_DETAILS: 'Mostrar detalles',
  SHOW_FIGURE: 'Mostrar Figura',
  SHOW_HIDE_MEASUREMENT_POINT: 'Ocultar/Mostrar puntos de medición',
  SHOW_MARKER: 'Mostrar marcador',
  SHOW_MEASUREMENT_POINT: 'Mostrar puntos de medición',
  SHOW_ON_MAP: 'Mostrar en el mapa',
  SHOW_ROUTE_OF_THIS_DAY: 'Ver recorrido de este dia',
  SPEED: 'Velocidad',
  STATUS: 'Estado',
  STILL: 'Parado',
  STORE: 'Tienda',
  TAGS: 'Etiquetas',
  TEAM: 'Equipo',
  TEAMS: 'Equipos',
  THIS_WEEK: 'Esta Semana',
  TIME_AGO: 'Hace {{time}}',
  TITLE: 'Título',
  TODAY: 'Hoy',
  TODAY_ASSIGNMENTS: 'Tareas de Hoy',
  TODAY_CHECK_IN_OUTS: 'Visitas de hoy',
  TODAY_GEOFENCES: 'Geocercas de hoy',
  TODAY_REPORTS: 'Reportes de hoy',
  TODAY_USER_LOCATION_REGISTER: 'Recorrido de hoy',
  TOOLS: 'Herramientas',
  TRACKING: 'Seguimiento',
  UPLOAD_KML: 'Cargar Archivo KML',
  USER: 'Usuario',
  USER_LOCATION: 'Ubicación de usuarios',
  USERS: 'Usuarios',
  VEHICLE_LOCATION: 'Ubicación de vehículos',
  VISITS_TODAY: 'Visitas de hoy',
  WALKING: 'Caminando',
  WAREHOUSE: 'Almacén',
  WHAT_DO_MAP: '¿Que deseas ver en el mapa?',
  WITH_LOCATION: 'Con ubicación',
  WITHCOORDS_DESCR: 'Estos son los clientes que tienen una ubicación asignada',
  WITHOUT_LOCATION: 'Sin ubicación',
  WITHOUTCOORDS_DESCR:
    'Estos son los clientes que no tienen una ubicación asignada, puedes indicar su ubicación en la edición de cada uno',
  YESTERDAY: 'Ayer',
  SHARE_LOCATION: 'Compartir Ubicación',
  SHARE_USER_LOCATION: 'Compartir ubicación de usuario',
  SHARE_USER_LOCATION_DESP:
    'Comparte la ubicación de este usuario con otras personas, por medio de un enlace, para que puedan ver su localización en tiempo real',
  HOW_MANY_TIME_SHARE: ' ¿Por cuánto tiempo quieres compartir la ubicación del usuario?',
  UNIT_TIME: 'Unidad de tiempo',
  MINUTES: 'Minutos',
  HOURS: 'Horas',
  DAYS: 'Días',
  UNDEFINED: 'Indefinido',
  AMOUNT_TIME: 'Cantidad de tiempo',
  SHARE_THIS_LINK:
    'Comparte este enlace con otras personas para que puedan ver la ubicación del usuario',
};

export default locale;
