/* eslint-disable camelcase */
import * as yup from 'yup';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FuseLoading from '@fuse/core/FuseLoading';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import IconButton from '@mui/material/IconButton';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { FormProvider, useForm } from 'react-hook-form';
import { getProjectsAll } from 'app/store/selectsData/projectsSlice';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectWorkspaceAux } from 'app/store/workspaceSlice';
import { yupResolver } from '@hookform/resolvers/yup';

import DetailsClient from './tabs/DetailsClient';
import LocationClient from './tabs/LocationClient';
import PlacesClient from './tabs/PlacesClient';
import LocatePlaceModal from '../modals/LocatePlaceModal';
import DeleteAllPlaces from '../modals/DeleteAllPlaces';
import DeletePlace from '../modals/DeletePlace';
import UnknownLocationClient from './tabs/UnknownLocationClient';
import LocationInfo from './tabs/LocationInfo';
import {
  getClient,
  selectClientById,
  selectClientData,
  setRightSidebarProps,
} from '../../store/clientsSlice';

/**
 * default values for the form
 */
const defaultValues = {
  name: '',
  description: '',
  address: '',
  data: {
    fields: [],
  },
  projects: [],
  seller: null,
  seller_data: {},
  sales_goal: '',
  contact_name: '',
  contact_phone: '',
  contact_email: '',
  approved: true,
  latitude: 0,
  longitude: 0,
  doc_type: 'client',
  schema: '1.0',
  invoice_tax_identifier: '',
  invoice_name: '',
  internal_code: '',
  location_mode: '',
  teams: [],
  places_data: [],
};

function ClientsSidebarContent() {
  /**
   * @constant {function} wsId - the id of the workspace
   * @constant {function} clientId - the id of the client
   * @constant {object} client - the client object
   * @constant {function} dispatch - the dispatch function from the redux store
   * @constant {function} navigate - the navigate function from the react-router-dom
   * @constant {object} AuxData - the auxiliary data of the workspace
   * @constant {function} isProjectsLoaded - if the projects are loaded
   * @constant {function} isTeamsLoaded - if the teams are loaded
   * @constant {function} t - the translation function
   * @constant {object} tab - the tab of the sidebar
   * @constant {boolean} editMode - if the user is in edit mode
   */
  const { wsId, clientId } = useParams();
  const client = useSelector((state) => selectClientById(state, clientId));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const AuxData = useSelector(selectWorkspaceAux);
  const { isProjectsLoaded } = useSelector(({ selectsData }) => selectsData.projects);
  const { isTeamsLoaded } = useSelector(({ selectsData }) => selectsData.teams);
  const { t } = useTranslation('managementApp');
  const { tab, editMode } = useSelector(selectClientData).rightSiderbar;

  /**
   * Close the sidebar
   */
  const onClose = () => navigate(`..${window.location.search}`);

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    name: yup.string().required(t('NAME_CANNOT_EMPTY')),
    places_data: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .when('delete', { is: undefined, then: yup.string().required(t('NAME_CANNOT_EMPTY')) }),
        latitude: yup
          .number()
          .when('delete', { is: undefined, then: yup.number().notOneOf([0], '') }),
        longitude: yup
          .number()
          .when('delete', { is: undefined, then: yup.number().notOneOf([0], '') }),
      })
    ),
  });

  /**
   * Form Methods
   */
  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    criteriaMode: 'firstError',
    shouldFocusError: true,
  });

  /**
   * Function to handle the tab change
   */
  const onChangeTab = (e, newTab) => dispatch(setRightSidebarProps({ tab: newTab }));

  /**
   * determine if the tab should be shown
   */
  const showTab = (tabI) => {
    const location_mode = editMode ? methods.watch('location_mode') : client.location_mode;

    if (tabI === 'places') return location_mode === 'through-client-places';

    if (tabI === 'map') return location_mode !== 'through-client-places';

    return false;
  };

  useEffect(() => {
    if (clientId === 'new') {
      dispatch(setRightSidebarProps({ editMode: true }));
      methods.reset(defaultValues);
      if (AuxData.general.default_teams_for_new_clients)
        methods.setValue('teams', AuxData.general.default_teams_for_new_clients);
    } else {
      dispatch(setRightSidebarProps({ editMode: false }));
      if (!client) dispatch(getClient({ wsId, clientId }));
    }

    dispatch(getProjectsAll(wsId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  if (
    (!client && clientId !== 'new') ||
    (clientId === 'new' && !editMode) ||
    !isProjectsLoaded ||
    !isTeamsLoaded
  ) {
    return (
      <div className="h-full flex-1 flex items-center">
        <FuseLoading />
      </div>
    );
  }

  return (
    <motion.div
      initial={{ y: 50, opacity: 0.8 }}
      animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
      className="flex flex-col flex-1  h-fullVH w-fullVW sm:h-full sm:w-full"
    >
      <div className="flex items-center justify-end w-full">
        {clientId !== 'new' && (
          <Tabs
            value={tab}
            onChange={onChangeTab}
            aria-label="tabs client"
            indicatorColor="secondary"
            textColor="secondary"
            className="flex-1 min-h-28 h-28"
          >
            <Tab
              className="min-h-24 h-2 !p-0 !py-10"
              icon={<AccountBoxIcon />}
              iconPosition="start"
              value="details"
              label={t('DETAILS')}
            />
            {showTab('map') && (
              <Tab
                className="min-h-24 h-2 !p-0 !py-10"
                icon={<LocationOnIcon />}
                iconPosition="start"
                value="map"
                label={t('LOCATION')}
              />
            )}
            {showTab('places') && (
              <Tab
                className="min-h-24 h-2 !p-0 !py-10"
                icon={<LocationCityIcon />}
                iconPosition="start"
                value="places"
                label={`${t('PLACES')} `}
              />
            )}
          </Tabs>
        )}
        <IconButton size="medium" onClick={onClose}>
          <FuseSvgIcon>heroicons-solid:x</FuseSvgIcon>
        </IconButton>
      </div>

      <FormProvider
        {...methods}
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {tab === 'details' && <DetailsClient />}
        {tab === 'map' && <LocationClient />}
        {tab === 'places' && <PlacesClient />}
        {tab === 'no_location' && <UnknownLocationClient />}
        {tab === 'location_info' && <LocationInfo />}
        <LocatePlaceModal />
        {/* <LocateClientModal /> */}
        <DeleteAllPlaces />
        <DeletePlace />
      </FormProvider>
    </motion.div>
  );
}

export default ClientsSidebarContent;
